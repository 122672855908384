/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import 'react-tagsinput/react-tagsinput.css';
import styles from './styles.module.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CiImageOff } from 'react-icons/ci';
import { FiRotateCw } from 'react-icons/fi';
import TagsInput from 'react-tagsinput';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { Button, IconButton } from 'components/buttons';
import { DropdownField, Error, Form, InputField } from 'components/forms';
import { CloseIcon, CopyIcon, LocationIcon } from 'components/icons';
import Bloc from 'components/panels/Bloc';
import { AppContext } from 'containers';
import {
  AsyncStateSelector, isEmpty, isFailed, isInProgress, isSuccessful, isUseless,
  success,
} from 'utils';

import { BackLoadingIndicator, BackPrint } from 'components/back';
import { AuthContext } from 'containers/auth/Login';
import { connect, disconnect, findLocations, getLocation, save } from './actions';
import { reducer } from './reducer';

const schema = yup.object().shape({
  name: yup.string().required('Required'),
  address: yup.string().required('Required'),
  reviewLink: yup.string().url('Invalid URL').required('Required'),
  client: yup.string().required('Required'),
  slug: yup.string().matches(/^[a-z][a-z0-9\-_]*$/g, {
    excludeEmptyString: true,
    message: 'Must start with a lower cased letter. Can contain digits, "_" and "-"',
  }),
  googleReviewLink: yup.object().shape({
    url: yup.string().url('Invalid URL'),
  }),
  yelpReviewLink: yup.object().shape({
    url: yup.string().url('Invalid URL'),
  }),
});


const LocationBloc = ({ className, location, clients, onClose, onSaved, ...rest }) => {
  const { useReducer } = React.useContext(AppContext);
  const { claims } = React.useContext(AuthContext);

  const isAdmin = _.get(claims, 'admin') === true;
  const isManager = _.get(claims, 'manager') === true;

  // state
  const [{
    saveState,
    connectState,
    disconnectState,
    locationsState,
    getLocationState,
    formData,
    formErrors,
  }, dispatch] = useReducer(reducer, {
    saveState: {},
    connectState: {},
    disconnectState: {},
    locationsState: {},
    getLocationState: {},
  }, {
    formData: {},
    formErrors: {},
  }, 'location-editor');
  const connected = _.get(connectState, 'value.status') === 'already-connected';
  // callbacks
  const callConnect = useCallback(() => dispatch(connect()));
  const callDisconnect = useCallback(() => dispatch(disconnect()));
  const callFindLocations = useCallback(async () => dispatch(findLocations()));
  const callGetLocation = useCallback(async (uid) => dispatch(getLocation(uid)));

  const validateAndCallSave = useCallback(async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
    } catch (error) {
      return error.inner.forEach(e =>
        dispatch({ type: 'UPDATE_FORM_ERROR', path: e.path, value: e.message }));
    }
    dispatch(save(formData, imageFile));
  }, [formData]);

  const onChange = useCallback(({ target: { name, value, checked, type } }) => {
    if (_.get(formErrors, name))
      dispatch({ type: 'UPDATE_FORM_ERROR', path: name, value: null });
    dispatch({
      type: 'UPDATE_FORM_ITEM',
      path: name,
      value: type === 'checkbox' ? checked : value,
    });
    if (name === 'slug') {
      dispatch({
        type: 'UPDATE_FORM_ITEM',
        path: 'reviewLink',
        value: 'https://feedback.patient10x.com/' +
          (_.isEmpty(value) ? location.uid : value)
      });
    }
  }, [formErrors, formData]);

  // state effects
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    const value = { ...location };
    if (clients && clients.find(c => c.uid === location.client) == null)
      value.client = '';
    if (_.isEmpty(value.improvetags)) {
      value.improvetags = [
        'Front Desk Staff',
        'Intake Forms',
        'Bedside Manner',
        'Customer Service',
        'Wait Time',
        'COVID Guidelines',
      ];
    }
    dispatch({ type: 'UPDATE_FORM_DATA', value });
  }, []);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FORM_ERROR',
      path: 'form',
      value: _.get(saveState, 'error.message', ''),
    }, [saveState.error]);
  }, [saveState.error]);

  useEffect(() => {
    if (!firstRender && isSuccessful(saveState))
      onSaved(saveState.value);
  }, [saveState.value]);

  useEffect(() => {
    if (isEmpty(connectState)) {
      if (isAdmin)
        callConnect();
      else
        dispatch({ type: 'UPDATE_CONNECT_STATE', value: success({}) });
    }
  }, [connectState.status]);

  useEffect(() => {
    if (isSuccessful(disconnectState)) {
      window.location.reload();
    }
  }, [disconnectState.status]);

  useEffect(() => {
    if (!formData.ref && connected && isUseless(locationsState))
      callFindLocations();
  }, [locationsState, connected]);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  useEffect(() => {
    if (firstRender) return;

    if (isSuccessful(getLocationState))
      dispatch({
        type: 'UPDATE_FORM_ITEM',
        path: 'googleReviewLink.url',
        value: _.get(getLocationState.value, 'metadata.newReviewUri', ''),
      });
    else if (isFailed(getLocationState))
      dispatch({
        type: 'UPDATE_FORM_ERROR',
        path: 'googleReviewLink.url',
        value: _.get(getLocationState.error, 'message', ''),
      });
  }, [getLocationState.status]);

  const copyToClipboard = useCallback((text) => {
    var textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      var successful = document.execCommand('copy');
      if (successful) toast.info('Copied to Clipboard', { autoClose: 1200 });
    } catch (err) {
      console.error('failed to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  });

  const [imageFile, setImageFile] = useState(null);

  return (
    <Bloc className={[styles.locationBloc, className].join(' ')} {...rest}>
      <div className={styles.header}>
        <span>{isManager ? location.name : _.isEmpty(location) ? 'Add New Location' : 'Edit Location'}</span>
        <IconButton onClick={onClose} >
          <CloseIcon />
        </IconButton>
      </div>
      <AsyncStateSelector state={connectState}
        onProgress={() => <BackLoadingIndicator height='200px' />}
        onFail={(error) => (
          <BackPrint>
            <p className='title'>Failed to retrieve Google Account details</p>
            <p className='message'>{_.get(error, 'message', 'Server Error')}</p>
            <Button text='Try Again' onClick={callConnect} />
          </BackPrint>
        )}
        onSuccess={(connectStateValue) => {
          const locations = formData.ref || _.isEmpty(formData.name) || _.isEmpty(_.get(locationsState, 'value.entities'))
            ? []
            : locationsState.value.entities
              .filter(l => l.title.toUpperCase().indexOf(formData.name.toUpperCase()) !== -1);
          return (
            <>
              <div
                className={styles.imagePicker}
                style={isAdmin ? {} : { display: 'none' }}>
                {formData.image ? (
                  <img
                    className={styles.image}
                    src={formData.image} />
                ) : (
                  <div>
                    <CiImageOff size={50} />
                  </div>
                )}
                <input
                  type='file'
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setImageFile(file);
                      onChange({
                        target: {
                          name: 'image',
                          value: URL.createObjectURL(file),
                        }
                      });
                    }
                  }} />
              </div>
              <Form
                className={styles.form}
                error={_.get(formErrors, 'form')} >
                <InputField
                  style={isAdmin ? {} : { display: 'none' }}
                  name='name'
                  label="Location Name"
                  placeholder="Enter location name"
                  value={_.get(formData, 'name', '')}
                  // readOnly
                  error={_.get(formErrors, 'name', '')}
                  onChange={onChange}
                  onSubmit={validateAndCallSave}
                  disabled={isInProgress(saveState)}
                />
                {_.isEmpty(locations)
                  ?
                  <>
                    <InputField
                      style={isAdmin ? {} : { display: 'none' }}
                      name='address'
                      as="textarea"
                      rows={2}
                      label='Address'
                      placeholder='Enter address'
                      value={_.get(formData, 'address', '')}
                      // readOnly
                      error={_.get(formErrors, 'address', '')}
                      onChange={onChange}
                      disabled={isInProgress(saveState)}
                    />
                    <div style={isAdmin ? {} : { display: 'none' }} >
                      <InputField
                        className={styles.fieldWithRightIcon}
                        name='slug'
                        label='Slug'
                        placeholder={location.uid}
                        value={_.get(formData, 'slug', '')}
                        // readOnly
                        error={_.get(formErrors, 'slug', '')}
                        onChange={onChange}
                        onSubmit={validateAndCallSave}
                        disabled={isInProgress(saveState)}
                      />
                      {!_.isEmpty(formData.name) &&
                        <IconButton
                          onClick={() => onChange({
                            target: {
                              name: 'slug',
                              value: _.words(_.toLower(formData.name)).join('-'),
                            }
                          })} >
                          <FiRotateCw className={styles.resetLinkIcon} size='17px' />
                        </IconButton>
                      }
                    </div>
                    <div style={isAdmin ? {} : { display: 'none' }} >
                      <InputField
                        className={styles.fieldWithRightIcon}
                        name='reviewLink.url'
                        label='Feedback Request Link'
                        placeholder='Enter or paste review request link'
                        value={_.get(formData, 'reviewLink', '')}
                        // readOnly
                        error={_.get(formErrors, 'reviewLink', '')}
                        onChange={onChange}
                        onSubmit={validateAndCallSave}
                        disabled={isInProgress(saveState)}
                      />
                      {
                        _.isEmpty(_.get(formData, 'reviewLink')) ? null :
                          <IconButton onClick={() => copyToClipboard(_.get(formData, 'reviewLink', ''))}>
                            <CopyIcon className={styles.copyReviewLinkIcon} />
                          </IconButton>
                      }
                    </div>
                    <div style={isAdmin ? {} : { display: 'none' }} >
                      <InputField
                        className={styles.fieldWithRightIcon}
                        name='googleReviewLink.url'
                        label='Google Review Link'
                        placeholder='Enter or paste review request link'
                        value={_.get(formData, 'googleReviewLink.url', '')}
                        error={_.get(formErrors, 'googleReviewLink.url', '')}
                        onChange={onChange}
                        onSubmit={validateAndCallSave}
                        disabled={!_.get(formData, 'googleReviewLink.enabled', false) || isInProgress(saveState)}
                      />
                      {location.uid && _.get(formData, 'googleReviewLink.enabled', false) &&
                        <IconButton onClick={() => !isInProgress(getLocationState) && callGetLocation(location.uid)}>
                          <FiRotateCw
                            className={styles.resetLinkIcon}
                            size='17px'
                            data-loading={isInProgress(getLocationState)}
                          />
                        </IconButton>}
                      <div className='checkboxes'>
                        <label>
                          <input
                            name='googleReviewLink.enabled'
                            type="checkbox"
                            checked={_.get(formData, 'googleReviewLink.enabled', false)}
                            onChange={onChange}
                          />
                          Enable
                        </label>
                      </div>
                    </div>
                    <div style={isAdmin ? {} : { display: 'none' }} >
                      <InputField
                        name='yelpReviewLink.url'
                        label='Yelp Review Link'
                        placeholder='Enter or paste review request link'
                        value={_.get(formData, 'yelpReviewLink.url', '')}
                        error={_.get(formErrors, 'yelpReviewLink.url', '')}
                        onChange={onChange}
                        onSubmit={validateAndCallSave}
                        disabled={!_.get(formData, 'yelpReviewLink.enabled', false) || isInProgress(saveState)}
                      />
                      <div className='checkboxes'>
                        <label>
                          <input
                            name='yelpReviewLink.enabled'
                            type="checkbox"
                            checked={_.get(formData, 'yelpReviewLink.enabled', false)}
                            onChange={onChange}
                          />
                          Enable
                        </label>
                      </div>
                    </div>
                    <div className={styles.taglist} style={isAdmin ? {} : { display: 'none' }} >
                      <span className='label'>Negative Review Improvments</span>
                      <TagsInput
                        name='improvetags'
                        value={_.get(formData, 'improvetags', [])}
                        onlyUnique
                        inputProps={{ placeholder: 'Improvment' }}
                        onChange={(tags) => {
                          dispatch({
                            type: 'UPDATE_FORM_ITEM',
                            path: 'improvetags',
                            value: tags
                          });
                        }}
                      />
                    </div>
                    <div className={styles.taglist} style={isAdmin ? {} : { display: 'none' }}>
                      <span className='label'>Snapshot Report</span>
                      <TagsInput
                        name='snapshotReportSettings.emails'
                        value={_.get(formData, 'snapshotReportSettings.emails', [])}
                        onlyUnique
                        inputProps={{ placeholder: 'Enter Email' }}
                        onChange={(tags) => {
                          dispatch({
                            type: 'UPDATE_FORM_ITEM',
                            path: 'snapshotReportSettings.emails',
                            value: tags.filter(t => /^\S+@\S+\.\S+$/.test(t))
                          });
                        }}
                      />
                      <div className='checkboxes'>
                        <label>
                          <input
                            name='snapshotReportSettings.kinds.weekly'
                            type="checkbox"
                            disabled={_.isEmpty(_.get(formData, 'snapshotReportSettings.emails'))}
                            checked={_.get(formData, 'snapshotReportSettings.kinds.weekly', false)}
                            onChange={onChange}
                          />
                          Weekly
                        </label>
                        <label>
                          <input
                            name='snapshotReportSettings.kinds.monthly'
                            type="checkbox"
                            disabled={_.isEmpty(_.get(formData, 'snapshotReportSettings.emails'))}
                            checked={_.get(formData, 'snapshotReportSettings.kinds.monthly', false)}
                            onChange={onChange}
                          />
                          Monthly
                        </label>
                      </div>
                    </div>
                    <div className={styles.taglist} style={isAdmin ? {} : { display: 'none' }}>
                      <span className='label'>Round Up Report</span>
                      <TagsInput
                        name='roundUpReportSettings.emails'
                        value={_.get(formData, 'roundUpReportSettings.emails', [])}
                        onlyUnique
                        inputProps={{ placeholder: 'Enter Email' }}
                        onChange={(tags) => {
                          dispatch({
                            type: 'UPDATE_FORM_ITEM',
                            path: 'roundUpReportSettings.emails',
                            value: tags.filter(t => /^\S+@\S+\.\S+$/.test(t))
                          });
                        }}
                      />
                      <div className='checkboxes'>
                        <label>
                          <input
                            name='roundUpReportSettings.kinds.newReviews'
                            type="checkbox"
                            disabled={_.isEmpty(_.get(formData, 'roundUpReportSettings.emails'))}
                            checked={_.get(formData, 'roundUpReportSettings.kinds.newReviews', false)}
                            onChange={onChange}
                          />
                          New Reviews
                        </label>
                        <label>
                          <input
                            name='roundUpReportSettings.kinds.negativeFeedbacks'
                            type="checkbox"
                            disabled={_.isEmpty(_.get(formData, 'roundUpReportSettings.emails'))}
                            checked={_.get(formData, 'roundUpReportSettings.kinds.negativeFeedbacks', false)}
                            onChange={onChange}
                          />
                          Negative Feedbacks
                        </label>
                      </div>
                    </div>
                    <div id='blacklist' className={styles.taglist} style={isManager ? {marginTop: 5} : {}}>
                      <span className='label'>Blacklist</span>
                      <TagsInput
                        name='blacklist'
                        value={_.get(formData, 'blacklist', [])}
                        onlyUnique
                        inputProps={{ placeholder: 'Enter Phone or Email' }}
                        onChange={(tags) => {
                          dispatch({
                            type: 'UPDATE_FORM_ITEM',
                            path: 'blacklist',
                            value: tags.filter(t => /^\S+@\S+\.\S+$/.test(t) ||
                              /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(t))
                          });
                        }}
                      />
                    </div>
                    {clients && (
                      <DropdownField
                        name='client'
                        as="select"
                        label='What client should this location be assigned to?'
                        placeholder='Choose client'
                        value={_.get(formData, 'client', '')}
                        error={_.get(formErrors, 'client', '')}
                        onChange={onChange}
                        disabled={isInProgress(saveState)}
                        options={clients.map(client => ({ value: client.uid, name: client.name }))}
                      />
                    )}
                    {isAdmin && (
                      <Row className={styles.gConnectPanel} noGutters>
                        <div className={styles.gLogoBorder}>
                          <img
                            alt="g-logo"
                            src="images/g-logo.png"
                            srcSet="images/g-logo.png 1x, images/g-logo@2x.png 2x" />
                        </div>
                        <Col>
                          <Error>{formErrors.connect}</Error>
                          <h5>Connect via Google.</h5>
                          <h6>This is used to access your GMB account</h6>
                        </Col>
                        {
                          connected
                            ? <Button
                              className={styles.gDisconnectButton}
                              text='Disconnect'
                              onClick={callDisconnect}
                            />
                            : <Button
                              className={styles.gConnectButton}
                              text='Connect'
                              onClick={() => window.open(connectStateValue.auth_url, '_self')}
                            />
                        }
                      </Row>
                    )}
                    <Button
                      className={styles.saveButton}
                      text={_.isEmpty(location) ? 'Add Location' : 'Save Changes'}
                      onClick={validateAndCallSave}
                      loading={isInProgress(saveState)} />
                  </>
                  : <div className={styles.locationPicker}>
                    {
                      locations.map(l => {
                        const address = l.storefrontAddress == null ? '' :
                          (l.storefrontAddress.addressLines.join(', ') + ', ' +
                            l.storefrontAddress.locality + ', ' +
                            l.storefrontAddress.administrativeArea);
                        const slug = _.words(_.toLower(l.title)).join('-');
                        return (
                          <div key={l.name}
                            className={styles.locationPickerItem}
                            onClick={() => {
                              dispatch({
                                type: 'UPDATE_FORM_DATA',
                                value: {
                                  ...formData,
                                  ...location,
                                  ref: l.name,
                                  name: l.title,
                                  address,
                                  slug,
                                  reviewLink: 'https://feedback.patient10x.com/' + slug,
                                  googleReviewLink: l.metadata.newReviewUri,
                                  client: formData.client,
                                }
                              });
                            }}>
                            <LocationIcon active height={23} width={20} />
                            <div className='content'>
                              <h5>{l.title}</h5>
                              <p>{address}</p>
                            </div>
                          </div>
                        );
                      })

                    }
                  </div>
                }
              </Form >
            </>
          );
        }}
      />
    </Bloc >
  );
};

LocationBloc.defaultProps = {
};
LocationBloc.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
  clients: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default LocationBloc;
